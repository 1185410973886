<template>
  <div>
    <button v-b-modal="`rankingEditModal${data.level}`" class="btn btn-primary btn-sm">
      <i class="fa fa-edit" /> แก้ไขข้อมูล
    </button>

    <b-modal v-model="modal" size="sm" :id="`rankingEditModal${data.level}`" title="แก้ไขข้อมูล" @ok="submit">
      <b-form>
        <b-form-group label="ระดับ" label-for="level">
          <b-form-input v-model="form.level"></b-form-input>
        </b-form-group>

        <div class="row">
          <div class="col-6">
            <b-form-group label="รายได้จากเงินคืน" label-for="cashback">
              <b-form-input v-model="form.cashback"></b-form-input>
            </b-form-group>
          </div>
          <div class="col-6">
            <b-form-group label="ค่าคอมมิชชั่น" label-for="commission">
              <b-form-input v-model="form.commission"></b-form-input>
            </b-form-group>
          </div>
          <!-- <div class="col-4">
            <b-form-group label="พอยท์" label-for="point">
              <b-form-input v-model="form.point"></b-form-input>
            </b-form-group>
          </div> -->
          <div class="col-6">
            <b-form-group label="ยอดฝาก" label-for="min_dep">
              <b-form-input v-model="form.min_dep"></b-form-input>
            </b-form-group>
          </div>
          <div class="col-6">
            <b-form-group label="ยอดเทิร์นสะสม" label-for="min_turn">
              <b-form-input v-model="form.min_turn"></b-form-input>
            </b-form-group>
          </div>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import {
  BForm, BAlert, BSpinner, BFormCheckboxGroup, BFormCheckbox, BListGroupItem, BListGroup, BBadge, BOverlay, BIconController, BInputGroupPrepend, BModal, BCard, BTable, BAvatar, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BButton, BCardBody
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component';
import moment from 'moment-timezone';
import 'flatpickr/dist/flatpickr.css';
export default {
  components: {
    BForm, BAlert, BSpinner, BFormCheckboxGroup, BFormCheckbox, BListGroupItem, BListGroup, BBadge, BOverlay, BIconController, BInputGroupPrepend, BModal, BCard, BTable, BAvatar, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BButton, BCardBody,
    flatPickr
  },
  directives: {
    Ripple
  },
  props: ['data'],
  emits: ['submit'],
  filters: {
    convertTime(date) {
      return moment(date).tz('Asia/Bangkok').format('HH:ss')
    },
  },
  data: () => ({
    modal: false,
    form: {
      id: '',
      level: '',
      cashback: '',
      commission: '',
      point: '',
      min_dep: '',
      min_turn: '',
    }
  }),
  mounted() {
    this.form.id = this.$props.data.id;
    this.form.level = this.$props.data.level;
    this.form.cashback = this.$props.data.cashback;
    this.form.commission = this.$props.data.commission;
    this.form.point = this.$props.data.point;
    this.form.min_dep = this.$props.data.min_dep;
    this.form.min_turn = this.$props.data.min_turn;
  },
  methods: {
    submit() {
      this.$emit('submit', this.form);
    }
  }
}
</script>